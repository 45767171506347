define('tenants/routes/pending', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!localStorage.getItem('accountId')) {
                this.transitionTo('login');
            }
        },
        model: function model() {
            var userId = this.get('session.data.authenticated.userId') || 0,
                accountId = this.get('session.data.authenticated.accountId');

            return Ember.RSVP.hash({
                account: this.store.findRecord('account', accountId),
                user: this.store.findRecord('user', userId),
                buildings: this.store.findAll('building', { active: true }, function (building) {
                    return building.get('active');
                }),
                requestTypeBuildings: this.store.findAll('request-type-building'),
                requestTypes: this.store.findAll('request-type'),
                requestLabels: this.store.findAll('request-label')
            });
        },
        afterModel: function afterModel(model) {
            var primaryBuilding = model.buildings.get('firstObject');

            Ember.$('body').addClass('full-screen').attr('style', primaryBuilding.get('backgroundImageStyle'));

            if (model.user.get('occupantStatus') !== 'pending') {
                this.transitionTo('portal');
            }
        }
    });
});