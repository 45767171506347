define('tenants/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'huyang-common/mixins/version-check', 'tenants/config/environment', 'npm:@sentry/browser'], function (exports, _applicationRouteMixin, _versionCheck, _environment, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_versionCheck.default, _applicationRouteMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            this.checkVersion();
            this.set('loadPrimaryBuilding', transition.targetName !== 'not-found');
        },

        model: function model() {
            // if user is logged in, get their primary building; else generic one
            var primaryBuildingId = this.get('session.data.authenticated.userId') || 0;
            return this.get('loadPrimaryBuilding') ? this.store.findRecord('primary-building', primaryBuildingId) : [];
        },

        afterModel: function afterModel(model) {
            if (model.get('account') && model.get('account.status') === 'inactive') {
                this.transitionTo('inactive');
            }
        },


        actions: {
            sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
                this.transitionTo('portal.requests');
            },
            sessionAuthenticationFailed: function sessionAuthenticationFailed() {
                this.controllerFor('login').set('loginErrorMessage', 'The username or password you entered is incorrect.  Please try again');
            },
            sessionInvalidationSucceeded: function sessionInvalidationSucceeded() {
                this.transitionTo('login');
            },
            error: function error(err /*, transition*/) {
                if (_environment.default.SENTRY_ENABLED) {
                    _browser.default.captureException(err, {
                        tags: { build: localStorage.getItem('build') },
                        extra: { errors: err.errors }
                    });
                }
                console.error(err.stack);
                if (err && err.status < 500) {
                    if (err.status === 404) {
                        return this.transitionTo('not-found');
                    } else {
                        return this.transitionTo('login');
                    }
                }
                return this.transitionTo('error');
            }
        }
    });
});