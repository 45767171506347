define('tenants/blueprints/page-object-component', ['exports', 'ember-cli-page-object/blueprints/page-object-component'], function (exports, _pageObjectComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pageObjectComponent.default;
    }
  });
});