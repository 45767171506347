define('tenants/components/tenant-user-form', ['exports', 'ember-validations', 'huyang-common/components/set-user-info', 'huyang-common/templates/components/set-user-info', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _setUserInfo, _setUserInfo2, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _setUserInfo.default.extend(_validatesEmail.default, {
        layout: _setUserInfo2.default,
        fields: function () {
            var controller = this;

            // validate email exists only on edit
            if (this.get('validateEmailExists')) {
                this.set('emailValidations.email', { currentValue: this.get('email') });
            }
            return [{
                name: 'name',
                label: 'Name',
                placeholder: 'John Smith',
                validations: { presence: true },
                value: this.get('name')
            }, {
                name: 'email',
                label: 'Email',
                placeholder: 'name@company.com',
                validations: this.get('emailValidations'),
                value: this.get('email')
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('mobilePhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }, {
                name: 'timezone',
                label: 'Timezone',
                helpText: 'Select your timezone',
                value: this.get('timezone'),
                timezoneSelect: true
            }, {
                name: 'photoUuid',
                label: 'Profile photo',
                photoUpload: true,
                photoUrl: this.get('photoUrl'),
                uploadUrl: this.get('uploadUrl')
            }];
        }.property('name', 'email', 'mobilePhone', 'title', 'photoUuid')
    });
});