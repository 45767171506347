define('tenants/routes/portal/requests/new', ['exports', 'tenants/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        documentTitle: 'Submit Request',
        session: Ember.inject.service(),

        model: function model() {
            // session.currentUser is a promise so make sure it's available
            return this.get('session.currentUser');
        }
    });
});