define('tenants/components/huyang-form-address', ['exports', 'huyang-common/components/huyang-form-address'], function (exports, _huyangFormAddress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _huyangFormAddress.default;
    }
  });
});