define('tenants/controllers/portal/requests/index', ['exports', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _sortsMultipleColumns) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_sortsMultipleColumns.default, {
        queryParams: ['sortColumns'],
        requestsController: Ember.inject.controller('portal/requests'),
        hasMultipleLocations: Ember.computed.alias('requestsController.hasMultipleLocations'),
        hidePriority: Ember.computed.alias('requestsController.hidePriority'),

        // upcomingAppointments: function() {
        //     // TODO: limit by time or number?
        //     var appointments = [];
        //     var now = moment();
        //     this.get('model.appointments').forEach(function(appt) {
        //         if (now.isBefore(appt.get('scheduledFrom'))) {
        //             appointments.push(appt);
        //         }
        //     });
        //     return appointments;
        // }.property('model.appointments.length'),

        actions: {
            goToRequest: function goToRequest(id) {
                this.transitionToRoute('portal.requests.request', id);
            }
        }
    });
});