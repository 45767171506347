define('tenants/controllers/portal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        needs: ['application'],
        primaryBuilding: Ember.computed.alias('controllers.application.model.firstObject'),

        flashBanner: function flashBanner(requestId) {
            var _this = this;

            this.set('goToRequestId', requestId);

            // clear any existing timeouts
            this.clearBannerTimeout();

            // show the banner
            Ember.$('.header-contents').addClass('flash');

            // dismiss the banner in 8s
            this.flashTimeout = setTimeout(function () {
                return _this.dismissBanner();
            }, 8000);
        },

        clearBannerTimeout: function clearBannerTimeout() {
            if (this.flashTimeout) {
                clearTimeout(this.flashTimeout);
            }
        },

        dismissBanner: function dismissBanner() {
            Ember.$('.header-contents').removeClass('flash');
        },

        cheatCode: function () {
            var _this2 = this;

            // a function that you can call from the console to test the banner
            window._flashIt = function () {
                return _this2.flashBanner(1000);
            };
        }.on('init'),

        actions: {
            goToRequest: function goToRequest() {
                this.transitionToRoute('portal.requests.request', this.get('goToRequestId'));
                this.dismissBanner();
            },
            dismissBanner: function dismissBanner() {
                this.clearBannerTimeout();
                this.dismissBanner();
            }
        }
    });
});