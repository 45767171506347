define('tenants/validators/email', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        call: function call() {
            var current = this.options.currentValue || '';
            var value = this.model.get(this.property);
            // let presence validator take care of blank
            // value might be undefined during load
            if (!value || current === value) {
                // unchanged is ok
                return;
            }
            var errors = this.errors;
            // this method can't be async; see
            // https://github.com/dockyard/ember-validations/blob/master/addon/validators/base.js#L62
            this.get('api').ajax({
                url: '/api/email_exists',
                async: false,
                data: { email: value },
                success: function success(response) {
                    if (response) {
                        errors.pushObject('is already in use');
                    }
                },
                error: function error() /*err*/{
                    errors.pushObject('is already in use');
                }
            });
        }
    });
});