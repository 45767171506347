define('tenants/components/image-gallery', ['exports', 'huyang-common/components/image-gallery'], function (exports, _imageGallery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _imageGallery.default;
    }
  });
});