define('tenants/components/tenant-visibility-checkbox', ['exports', 'huyang-common/components/tenant-visibility-checkbox'], function (exports, _tenantVisibilityCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tenantVisibilityCheckbox.default;
    }
  });
});