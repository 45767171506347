define('tenants/routes/login-failed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.findRecord('primary-building', 0);
        },

        afterModel: function afterModel(model) {
            Ember.$('body').addClass('full-screen').attr('style', model.get('backgroundImageStyle'));
        }
    });
});