define('tenants/routes/logout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            return this.get('api').ajax({
                type: 'POST',
                url: '/api/logout',
                success: this.logoutSuccess.bind(this)
            });
        },
        logoutSuccess: function logoutSuccess() {
            if (this.get('session.isAuthenticated')) {
                this.get('session').invalidate();
            }

            this.transitionTo('login');
        }
    });
});