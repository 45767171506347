define('tenants/components/sms-threshold-label', ['exports', 'huyang-common/components/sms-threshold-label'], function (exports, _smsThresholdLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _smsThresholdLabel.default;
    }
  });
});