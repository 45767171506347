define('tenants/components/select-input', ['exports', 'huyang-common/components/select-input'], function (exports, _selectInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _selectInput.default;
    }
  });
});