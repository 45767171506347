define('tenants/adapters/request-comment', ['exports', 'huyang-common/adapters/request-comment'], function (exports, _requestComment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestComment.default.extend({
        pathForType: function pathForType() /*type*/{
            return 'tenant/requestComments';
        }
    });
});