define('tenants/components/channel-picker', ['exports', 'huyang-common/components/channel-picker'], function (exports, _channelPicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _channelPicker.default;
    }
  });
});