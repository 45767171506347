define('tenants/models/request-type', ['exports', 'huyang-common/models/request-type'], function (exports, _requestType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requestType.default;
    }
  });
});