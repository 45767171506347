define('tenants/utils/rrule-utils', ['exports', 'huyang-common/utils/rrule-utils'], function (exports, _rruleUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rruleUtils.default;
    }
  });
});