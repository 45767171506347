define('tenants/components/vendor-autocomplete-input', ['exports', 'huyang-common/components/vendor-autocomplete-input'], function (exports, _vendorAutocompleteInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _vendorAutocompleteInput.default;
    }
  });
});