define('tenants/controllers/portal/settings/info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        user: Ember.computed.alias('model'),

        actions: {
            fileUploaded: function fileUploaded(photoUuid) {
                var user = this.get('user');
                user.set('photoUuid', photoUuid);
            }
        }
    });
});