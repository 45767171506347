define('tenants/routes/portal/requests/request', ['exports', 'tenants/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            return Ember.RSVP.hash({
                request: this.store.findRecord('request', params.request_id, { reload: true }),
                channels: this.store.findAll('channel')
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('requestLabels', this.modelFor('portal.requests').requestLabels);
        }
    });
});