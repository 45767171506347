define('tenants/controllers/portal/requests/archive', ['exports', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _sortsMultipleColumns) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_sortsMultipleColumns.default, {
        queryParams: ['sortColumns'],
        requestsController: Ember.inject.controller('portal/requests'),
        hasMultipleLocations: Ember.computed.alias('requestsController.hasMultipleLocations'),
        hidePriority: Ember.computed.alias('requestsController.hidePriority'),

        actions: {
            goToRequest: function goToRequest(id) {
                this.transitionToRoute('portal.requests.request', id);
            }
        }
    });
});