define('tenants/components/set-request-type-info', ['exports', 'huyang-common/components/set-request-type-info'], function (exports, _setRequestTypeInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _setRequestTypeInfo.default;
    }
  });
});