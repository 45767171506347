define('tenants/controllers/login', ['exports', 'huyang-common/controllers/login'], function (exports, _login) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _login.default.extend({
        account: Ember.computed.alias('model.account'),

        getLookupUrl: function getLookupUrl() {
            return '/api/tenant/email_lookup';
        },

        selfSignupEnabled: function () {
            return this.get('account.occupantConfig.auto_create');
        }.property('model'),

        confirmEmail: function () {
            return !this.get('blocked') && !this.get('linkSent') && this.get('selfSignupEnabled') && (this.get('lookupFailed') || this.get('creating'));
        }.property('blocked', 'linkSent', 'selfSignupEnabled', 'lookupFailed'),

        welcome: function () {
            return !this.get('linkSent') && !this.get('confirmEmail') && !this.get('blocked');
        }.property('linkSent', 'lookupFailed'),

        requiresApproval: function () {
            if (this.get('status') === 'pending') {
                return true;
            }

            var config = this.get('account.occupantConfig');

            if (!config.approval_required) {
                return false;
            }

            var domainMatch = this.get('email').match(/@(.*)/);

            if (!domainMatch || domainMatch.length < 2) {
                return true;
            }

            var emailDomain = domainMatch[1].trim();
            var autoDomains = config.approved_domains || '';

            var auto = autoDomains.split(',').find(function (domain) {
                return domain.trim() === emailDomain;
            });

            return !auto;
        }.property('model.account', 'status'),

        actions: {
            sendLinkOrConfirm: function sendLinkOrConfirm() {
                var _this = this;

                this.sendLoginLink(this.get('email'), false).then(function () {
                    if (_this.get('blocked')) {
                        _this.transitionToRoute('login-blocked');
                    }
                });
            },

            cancel: function cancel() {
                this.set('lookupFailed', false);
                this.set('creating', false);
                this.set('badEmail', '');
            },

            createAndSendLink: function createAndSendLink() {
                var _this2 = this;

                this.set('creating', true);
                this.sendLoginLink(this.get('email'), this.get('selfSignupEnabled')).then(function () {
                    _this2.set('creating', false);
                });
            }
        }
    });
});