define('tenants/routes/portal/index', ['exports', 'tenants/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        beforeModel: function beforeModel() {
            this.transitionTo('portal.requests');
        }
    });
});