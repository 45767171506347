define('tenants/routes/print/request', ['exports', 'tenants/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                account: this.get('session.currentAccount'),
                buildings: this.store.findAll('building', { withRole: true }),
                channels: this.store.findAll('channel'),
                request: this.store.findRecord('request', params.request_id, { reload: true })
            });
        }
    });
});