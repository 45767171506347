define('tenants/components/request-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        scrollTo: function () {
            var scrollTo = this.get('scrollToId');
            if (scrollTo) {
                var req = Ember.$('#row' + scrollTo);
                if (req && req.length) {
                    Ember.$('html, body').animate({
                        scrollTop: req.offset().top - 130 // for fixed header
                    }, 0);
                }
            }
        }.on('didRender'),

        tableColumns: function () {
            var columns = [Ember.Object.create({ key: 'workOrder', label: 'ID', phraseFormat: "#%@" }), Ember.Object.create({ key: 'requestType.label', iconClass: 'requestType.cssClass', label: 'Type', nowrap: true }), Ember.Object.create({ label: 'Short description', key: 'title', link: 'portal.requests.request', disableLinkAction: true })];

            if (this.get('showLocation')) {
                columns.push(Ember.Object.create({ key: 'location', label: 'Location' }));
            }

            columns.push(Ember.Object.create({ label: 'Last updated', isDate: true, relativeDate: true, key: 'updated', isSorted: true }));

            if (!this.get('hidePriority')) {
                columns.push(Ember.Object.create({ label: 'Priority', isPriority: true, key: 'priority' }));
            }

            return columns;
        }.property('showLocation', 'hidePriority'),

        actions: {
            rowClicked: function rowClicked(id) {
                this.sendAction('rowClicked', id);
            },
            infinityLoad: function infinityLoad() {
                this.sendAction('infinityLoad');
            },
            sortBy: function sortBy(sortColumn, sortDirection) {
                this.sendAction('sortBy', sortColumn, sortDirection);
            }
        }
    });
});