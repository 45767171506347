define('tenants/routes/portal/requests/archive', ['exports', 'tenants/routes/portal/requests/request-list-base'], function (exports, _requestListBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestListBase.default.extend({
        model: function model() {
            return this.infinityModel('request', {
                perPage: this.get('perPage'),
                startingPage: 1,
                filter: 'closed',
                sortColumn: this.get('sortColumn'),
                sortDirection: this.get('sortDirection')
            });
        }
    });
});