define('tenants/models/cost-log', ['exports', 'huyang-common/models/cost-log'], function (exports, _costLog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _costLog.default;
    }
  });
});