define('tenants/models/view-config', ['exports', 'huyang-common/models/view-config'], function (exports, _viewConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _viewConfig.default;
    }
  });
});