define('tenants/validators/subdomain', ['exports', 'huyang-common/validators/subdomain'], function (exports, _subdomain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subdomain.default;
    }
  });
});