define('tenants/controllers/portal/requests/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        needs: ['portal'],
        portalController: Ember.inject.controller('portal'),
        requestsController: Ember.inject.controller('portal/requests'),
        account: Ember.computed.alias('portalController.model.account'),
        buildings: Ember.computed.alias('portalController.model.buildings'),
        requestTypes: Ember.computed.alias('portalController.model.requestTypes'),
        requestLabels: Ember.computed.alias('portalController.model.requestLabels'),
        hideDueDate: Ember.computed.alias('requestsController.hideDueDate'),
        showInlineDueDate: Ember.computed.not('hideDueDate'),

        session: Ember.inject.service(),

        // Return array with user so validations can pass on selectedFollowers
        users: function () {
            return [this.get('session.currentUser')];
        }.property(),

        // Return array with user's ID so they are added as a follower
        selectedFollowers: function () {
            return [this.get('session.currentUser.id')];
        }.property(),

        hidePriority: function () {
            var oc = this.get('account.occupantConfig');

            if (oc) {
                return !oc.priority_allowed; // need to flip the boolean for our "hide" convention
            }

            return false;
        }.property('account.occupantConfig'),

        actions: {
            cancel: function cancel() {
                this.transitionToRoute('portal.requests');
            },
            requestSaved: function requestSaved(request) {
                this.transitionToRoute('portal.requests');
                Ember.getOwner(this).lookup('controller:portal').flashBanner(request.get('id'));
            }
        }
    });
});