define('tenants/controllers/pending/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        account: Ember.computed.alias('model.account'),
        buildings: Ember.computed.alias('model.buildings'),
        requestTypes: Ember.computed.alias('model.requestTypes'),
        requestLabels: Ember.computed.alias('model.requestLabels'),

        session: Ember.inject.service(),

        hidePriority: function () {
            return !this.get('account.occupantConfig.priority_allowed');
        }.property('account.occupantConfig'),

        hideDueDate: function () {
            return !this.get('account.occupantConfig.due_date_allowed');
        }.property('account.occupantConfig'),

        actions: {
            cancel: function cancel() {
                this.transitionToRoute('pending');
            },
            save: function save(info, attachments) {
                var _this = this;

                info.created = new Date();
                info.updated = info.created;
                info.opened = info.created;
                info.secondsOpen = 0;
                info.status = 'open';
                info.originator = this.get('session.currentUser');
                if (attachments && attachments.length) {
                    info.attachment = attachments.map(function (file) {
                        return file.get('id');
                    });
                }
                // add originator as follower
                info.followerUsers = [this.get('session.currentUser.id')];

                var request = this.store.createRecord('request', info);

                request.save().then(function () {
                    _this.transitionToRoute('pending.success');
                });
            },
            requestSaved: function requestSaved() {
                this.transitionToRoute('pending.success');
            }
        }
    });
});