define('tenants/services/api', ['exports', 'huyang-common/services/api'], function (exports, _api) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _api.default;
    }
  });
});