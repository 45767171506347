define('tenants/adapters/building', ['exports', 'tenants/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() /*type*/{
            return 'tenant/buildings';
        }
    });
});