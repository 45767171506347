define('tenants/components/bootstrap-modal', ['exports', 'huyang-common/components/bootstrap-modal'], function (exports, _bootstrapModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bootstrapModal.default;
    }
  });
});