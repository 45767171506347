define('tenants/components/repeat-schedule-picker', ['exports', 'huyang-common/components/repeat-schedule-picker'], function (exports, _repeatSchedulePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _repeatSchedulePicker.default;
    }
  });
});