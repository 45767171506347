define('tenants/components/sortable-table', ['exports', 'huyang-common/components/sortable-table'], function (exports, _sortableTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sortableTable.default;
    }
  });
});