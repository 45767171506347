define('tenants/router', ['exports', 'tenants/config/environment', 'huyang-common/mixins/tracked'], function (exports, _environment, _tracked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_tracked.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('inactive');
    this.route('login');
    this.route('login-token', { path: '/token/:token' });
    this.route('login-failed');
    this.route('logout');
    this.route('portal', { path: '/' }, function () {
      this.route('requests', function () {
        this.route('new');
        this.route('request', { path: '/:request_id' });
        this.route('archive', { path: '/closed' });
      });

      this.route('settings', function () {
        this.route('notifications');
        this.route('mobile');
        this.route('info');
        this.route('logout');
      });

      this.route('profiles', { path: '/profiles' }, function () {
        this.route('user', { path: '/:user_id' });
      });
    });
    this.route('print', function () {
      this.route('request', { path: ':request_id' });
    });
    this.route('error');
    this.route('not-found');
    this.route('pending', function () {
      this.route('new');
      this.route('success');
    });
    this.route('catchall', { path: '/*wildcard' });
  });

  exports.default = Router;
});