define('tenants/adapters/request-attachment', ['exports', 'tenants/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            var url = this._super(modelName, id, snapshot, requestType, query);
            if (requestType === 'updateRecord') {
                url = url.replace('tenant/requestAttachments', 'requestAttachments');
            }
            return url;
        },
        pathForType: function pathForType() /*type*/{
            return 'tenant/requestAttachments';
        }
    });
});