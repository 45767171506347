define('tenants/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        documentTitle: 'Login',

        renderTemplate: function renderTemplate() {
            this.render('login', { into: 'application' });
        },

        model: function model() {
            return this.store.findRecord('primary-building', 0);
        },

        afterModel: function afterModel(model) {
            this._super(model);

            Ember.$('body').addClass('full-screen').attr('style', model.get('backgroundImageStyleFull'));
        }
    });
});