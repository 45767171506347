define('tenants/controllers/portal/profiles/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        isSelf: function () {
            return parseInt(this.get('model.id')) === parseInt(this.get('session.data.authenticated.userId'));
        }.property('model.id'),

        actions: {
            editProfile: function editProfile() {
                this.transitionToRoute('portal.settings');
            }
        }
    });
});