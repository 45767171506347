define('tenants/serializers/pm-program', ['exports', 'huyang-common/serializers/pm-program'], function (exports, _pmProgram) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pmProgram.default;
    }
  });
});