define('tenants/validators/phone', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        call: function call() {
            // let presence validator take care of blank
            if (!this.model.get(this.property)) {
                return;
            }
            var phoneRegexp = /^\(?(\d{3})\)?[- \.]?(\d{3})[- \.]?(\d{4})(\s*x ?\d+)?\s*$/;
            if (!phoneRegexp.test(this.model.get(this.property))) {
                this.errors.pushObject('must be a 10 digit phone number');
            }
        }
    });
});