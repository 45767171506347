define('tenants/adapters/request', ['exports', 'huyang-common/adapters/request'], function (exports, _request) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _request.default.extend({
        pathForType: function pathForType() /*type*/{
            return 'tenant/requests';
        }
    });
});