define('tenants/routes/login-token', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            return this.get('session').authenticate('authenticator:custom', {
                token: params.token
            });
        },

        afterModel: function afterModel() {
            this.transitionTo('portal.requests');
        },

        actions: {
            // authenticate failed: show error
            error: function error() /* error, transition*/{
                this.transitionTo('login-failed');
            }
        }
    });
});