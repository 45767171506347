define('tenants/routes/inactive', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            var primaryBuildingId = this.get('session.data.authenticated.userId') || 0;
            return this.store.findRecord('primary-building', primaryBuildingId);
        },

        afterModel: function afterModel(model) {
            this._super(model);

            if (model.get('account.status') !== 'inactive') {
                this.transitionTo('application');
            }

            Ember.$('body').addClass('full-screen').attr('style', model.get('backgroundImageStyleFull'));
        }
    });
});