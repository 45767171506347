define('tenants/routes/portal', ['exports', 'tenants/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            // if user is logged in, get their primary building; else generic one
            var primaryBuildingId = this.get('session.data.authenticated.userId') || 0;

            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                user: this.store.findRecord('user', this.get('session.data.authenticated.userId')),
                buildings: this.store.query('building', { active: true }),
                primaryBuilding: this.store.findRecord('primary-building', primaryBuildingId),
                requestTypes: this.store.findAll('request-type'),
                requestLabels: this.store.findAll('request-label')
            });
        },

        afterModel: function afterModel(model) {
            if (model.user.get('occupantStatus') === 'pending') {
                this.transitionTo('pending');
            }
        }
    });
});