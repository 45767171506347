define('tenants/controllers/portal/requests/request', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        requestsController: Ember.inject.controller('portal/requests'),
        hidePriority: Ember.computed.alias('requestsController.hidePriority'),
        hideDueDate: Ember.computed.alias('requestsController.hideDueDate'),

        attachmentsCount: function () {
            var count = this.get('model.request.attachments.length');
            return count === 1 ? '1 Attachment' : count + ' Attachments';
        }.property('model.request.attachments'),

        actions: {
            save: function save(info, attachments) {
                var request = this.get('model.request');
                // tenants can only set request type and description
                request.set('updated', new Date());
                request.set('requestType', info.requestType);
                request.set('description', info.description);
                request.save().then(function (wo) {
                    if (attachments && attachments.length) {
                        var attachmentIds = attachments.map(function (file) {
                            return file.get('id');
                        });
                        wo.addAttachments(attachmentIds);
                    }
                });
                this.set('showEdit', false);
            },

            addComment: function addComment(info, attachments, callback) {
                var request = this.get('model.request');
                info.request = request;

                var channel = this.get('model.channels').findBy('isOccupant');

                if (channel) {
                    info.channel = channel;
                }

                if (attachments && attachments.length) {
                    info.attachment = attachments.map(function (file) {
                        return file.get('id');
                    });
                }

                // set body to unrendered version so it'll be visible immediately
                info.renderedBody = info.body;

                var comment = this.store.createRecord('request-comment', info);

                comment.save().then(function () /*commentObject*/{
                    if (typeof callback === 'function') {
                        callback();
                    }
                });
            }
        }
    });
});