define('tenants/routes/portal/requests/request-list-base', ['exports', 'tenants/routes/base-account', 'ember-infinity/mixins/route'], function (exports, _baseAccount, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_route.default, {
        queryParams: {
            sortColumns: { refreshModel: true }
        },

        sortSaveKey: 'requestsSorting',
        perPage: 25,

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            if (model.requests && model.requests.meta) {
                var meta = model.requests.meta;
                var total = meta && meta.total ? meta.total : model.requests.length;
                controller.set('total', total);
            }
            controller.set('sortColumn', this.get('sortColumn'));
            controller.set('sortDirection', this.get('sortDirection'));
        },

        sortColumn: function () {
            return sessionStorage.getItem(this.get('sortSaveKey')) || 'updated';
        }.property().volatile(),

        sortDirection: function () {
            return sessionStorage.getItem(this.get('sortSaveKey') + 'Dir') || 'desc';
        }.property().volatile(),

        actions: {
            sortBy: function sortBy(sortColumn, sortDirection) {
                sessionStorage.setItem(this.get('sortSaveKey'), sortColumn);
                sessionStorage.setItem(this.get('sortSaveKey') + 'Dir', sortDirection);
                this.refresh();
            }
        }
    });
});