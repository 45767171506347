define('tenants/routes/base-account', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        beforeModel: function beforeModel(transition) {
            this._super(transition);
            Ember.$('body').removeClass('full-screen').attr('style', '');
        }
    });
});