define('tenants/controllers/pending/success', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            createRequest: function createRequest() {
                this.transitionToRoute('pending.new');
            }
        }
    });
});