define('tenants/components/notifications-table', ['exports', 'huyang-common/components/notifications-table'], function (exports, _notificationsTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _notificationsTable.default;
    }
  });
});