define('tenants/helpers/get', ['exports', 'huyang-common/helpers/get'], function (exports, _get) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _get.default;
    }
  });
  Object.defineProperty(exports, 'get', {
    enumerable: true,
    get: function () {
      return _get.get;
    }
  });
});