define('tenants/controllers/portal/requests', ['exports', 'huyang-common/mixins/tracked'], function (exports, _tracked) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_tracked.default, {
        portalController: Ember.inject.controller('portal'),
        buildings: Ember.computed.alias('portalController.model.buildings'),
        account: Ember.computed.alias('portalController.model.account'),

        hasMultipleLocations: function () {
            if (this.get('buildings.length') > 1) {
                return true;
            }

            var building = this.get('buildings.firstObject');

            return building && building.get('activeSubspaces.length') > 1;
        }.property('buildings.length', 'buildings.@each.activeSubspaces'),

        hidePriority: function () {
            var oc = this.get('account.occupantConfig');

            if (oc) {
                return !oc.priority_allowed;
            }

            return false;
        }.property('account.occupantConfig'),

        hideDueDate: function () {
            var oc = this.get('account.occupantConfig');

            if (oc) {
                return !oc.due_date_allowed;
            }

            return false;
        }.property('account.occupantConfig'),

        openRequests: function () {
            return this.get('model').filterBy('status', 'open');
        }.property('model.@each.status'),

        closedRequests: function () {
            return this.get('model').filterBy('status', 'closed');
        }.property('model.@each.status')
    });
});